
// modules
import session from './modules/session'
import lists from './modules/lists'
import search from './modules/search';
import localstorage from './modules/localstorage';

//Alert
import alertResponse from './modules/alert_response';
//dashboard
import dashboard from "./modules/dashboard";
//project
import project from './modules/project';
//addressbook
import addressbook from "./modules/addressbook/addressbook";
//addressbook_org
import org_and_user from './modules/addressbook/org_and_user';
//news
import news from './modules/groups/groups';
//docs
import documents from './modules/docs/documents';

//KnowledgeHub
import knowledgeHub from './modules/knowledgeHub/knowledgeHub';

//Calendar
import calendar from './modules/calendar/calendar';
//Labs
import labs from './modules/laboratories/labs';

//Reports
import reports from './modules/reports/reports';

//Footer Links
import project_tests from './modules/project_tests';
//change_log
import change_log from './modules/change_log';

// Help
import help from './modules/help';

//VueTour
import VueTour from './modules/vue_tour';


export default {
  session: session,
  search: search,
  lists: lists,
  localstorage: localstorage,
  alertResponse: alertResponse,
  dashboard: dashboard,
  project: project,
  history: history,
  addressbook: addressbook,
  news: news,
  org_and_user: org_and_user,
  labs: labs,
  reports: reports,
  calendar: calendar,
  documents: documents,
  project_tests: project_tests,
  change_log: change_log,
  knowledgeHub: knowledgeHub,
  help: help,
  VueTour: VueTour,
}
