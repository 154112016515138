import axios from 'axios';

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    keyDocuments: null,
    docsPublicFolders: null,
    docsPublicFoldersList: null,
    docsSharedFolders: null,
}
const getters = {
    keyDocuments: state => state.keyDocuments,
    docsPublicFolders: state => state.docsPublicFolders,
    docsPublicFoldersList: state => state.docsPublicFoldersList,
    docsSharedFolders: state => state.docsSharedFolders
}

const mutations = {
    setKeyDocuments: (state, data ) => state.keyDocuments = data,
    setDocsPublicFolders: (state, data) => state.docsPublicFolders = data,
    setDocsPublicFoldersList: (state, data) => state.docsPublicFoldersList = data,
    setDocsSharedFolders: (state, data) => state.docsSharedFolders = data
}

const actions = {
    fetchKeyDocuments({commit}) {
        axios.get('/documents.json').then(res => {
            commit('setKeyDocuments', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async pinDocument({commit, dispatch}, id) {

        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
        };

        try {
            const res = await axios.post(`/settings/pin_doc/${id}.json`, params,{ headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            dispatch('fetchSession')
            commit('setAlertResponse', {type: 'successful', action: 'pin', text: 'document'});

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'lock', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async unPinDocument({commit, dispatch}, id) {
        try {
            const res = await axios.delete(`/settings/unpin_doc/${id}.json`,{
                headers: {  'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }})

            if(res.data.error) throw res.data.message;
            dispatch('fetchSession')
            commit('setAlertResponse', {type: 'successful', action: 'unpin', text: 'document'});

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'lock', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchDocsPublicFolders({commit}) {
        axios.get('/pub_folders.json').then(res => {
            commit('setDocsPublicFolders', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchDocsPublicFolderListDocuments({commit}, id) {
        axios.get(`/doc_folders/${id}.json`).then(res => {
            commit('setDocsPublicFoldersList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchDocsSharedFolders({commit}) {
        axios.get('/shared_folders.json').then(res => {
            commit('setDocsSharedFolders', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
}
