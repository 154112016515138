import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { VTreeview } from 'vuetify/labs/VTreeview'

const customLightTheme = {
    dark: false,
    colors: {
        primary: '#689F38',
        primaryFont: '#000',
        secondaryFont: '#383838',
        iconColor:  '#2d2d2d',
        iconColorLight:  '#646363',
        link: '#406520',
        secondary: '#4a7902',
        kh: '#e78e60',
        accent: '#2a3439',
        error: '#EF5350',
        info: '#536DFE',
        success: '#8BC34A',
        warning: '#FFC107',
        tooltip:'#252525',
        warningTooltip:'#D32F2F',
        plBackground: '#f8f8f8',
        toolbarTabsBackground: '#f1f1f1',
    },
    variables: {
        myColor: '#ff0000'
    }
}

export default createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },

    },
    theme: {
        defaultTheme: 'customLightTheme',
        themes: {
            customLightTheme,
        },
    },
    components: {
        ...components,
        VTreeview,
    },
    directives,
})