import axios from 'axios';

/* STATE * * * * * * * * * * * * * * * * * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    helpTour: false,
    openHelpDrawer: false,
    helpWeight: null,
    helpDrawer: null,
    helpDrawerScheme: null,
    helpDrawerLinksScheme: null,
    helpDrawerKeywordsScheme: null,
    helpFileUploadProgress: null,
}

/* GETTERS * * * * * * * * * * * * * * * * * * */
const getters = {
    openHelpDrawer: state => state.openHelpDrawer,
    helpTour: state => state.helpTour,
    helpWeight: state => state.helpWeight,
    helpDrawer: state => state.helpDrawer,
    helpDrawerLinks: state => state.helpDrawerLinks,
    helpDrawerKeywords: state => state.helpDrawerKeywords,
    helpDrawerScheme: state => state.helpDrawerScheme,
    helpDrawerLinksScheme: state => state.helpDrawerLinksScheme,
    helpDrawerKeywordsScheme: state => state.helpDrawerKeywordsScheme,
    helpFileUploadProgress: state => state.helpFileUploadProgress,
}

/* MUTATIONS * * * * * * * * * * * * * * * * * * */
const mutations = {
    setOpenHelpDrawer: (state, data) =>   state.openHelpDrawer = data,
    setHelpTour: (state, data) => state.helpTour = data,
    setHelpWeight: (state, data) => state.helpWeight = data,
    setHelpDrawer: (state, data) =>   state.helpDrawer = data,
    setHelpDrawerLinks: (state, data) =>   state.helpDrawerLinks = data,
    setHelpDrawerKeywords: (state, data) =>   state.helpDrawerKeywords = data,
    setHelpDrawerScheme: (state, data) =>   state.helpDrawerScheme = data,
    setHelpDrawerLinksScheme: (state, data) =>   state.helpDrawerLinksScheme = data,
    setHelpDrawerKeywordsScheme: (state, data) =>   state.helpDrawerKeywordsScheme = data,
    setHelpFileUploadProgress: (state, percentage) => state.helpFileUploadProgress = percentage,
}


/* ACTIONS * * * * * * * * * * * * * * * * * * */
const actions = {
    setHelpTour({commit}, data) {
        commit('setHelpTour', data);
    },

    fetchHelpDrawer({commit}, weight) {
        axios.get(`/help/${weight}.json`).then(res => {
            commit('setHelpDrawer', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchCreateHelpDrawerScheme({commit}) {
        axios.get(`/help/new.json`).then(res => {
            commit('setHelpDrawerScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createHelpDrawer({ commit, dispatch },help) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                help,
            };

            const res = await axios.post(`/help.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: 'created', text: 'a new Help Details', data: res.data});
            dispatch('fetchHelpDrawer', res.data.weight);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },



    fetchEditHelpDrawerScheme({commit}, help_id) {
        axios.get(`/help/${help_id}/edit.json`).then(res => {
            commit('setHelpDrawerScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateHelpDrawer({commit, dispatch}, { help, help_id }) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                help,
            };

            const res = await axios.put(`/help/${help_id}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'Help Details', data: res.data});
            dispatch('fetchHelpDrawer', res.data.weight);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    fetchCreateHelpDrawerLinksScheme({commit}, help_id) {
        axios.get(`/help/${help_id}/help_links/new.json`).then(res => {
            commit('setHelpDrawerLinksScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createHelpDrawerLink({commit, dispatch}, help_link) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                help_link,
            };

            const res = await axios.post(`/help/${help_link.help_id}/help_links.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            dispatch('fetchHelpDrawer', res.data.weight);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async deleteHelpDrawerLink({commit, dispatch},  {id, help_weight}) {
        try {
            const res = await  axios.delete(`/help_links/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            });

            if(res.data.error) throw res.data.message
            dispatch('fetchHelpDrawer', help_weight);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    fetchCreateHelpDrawerKeywordsScheme({commit}, help_id) {
        axios.get(`/help/${help_id}/help_keywords/new.json`).then(res => {
            commit('setHelpDrawerKeywordsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createHelpDrawerKeyword({commit, dispatch}, help_keyword) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                help_keyword,
            };

            const res = await axios.post(`/help/${help_keyword.help_id}/help_keywords.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            dispatch('fetchHelpDrawer',  res.data.weight);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async deleteHelpDrawerKeyword({commit, dispatch}, {id, help_weight}) {
        try {

            const res = await  axios.delete(`/help_keywords/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            });

            if(res.data.error) throw res.data.message
            dispatch('fetchHelpDrawer', help_weight);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async uploadHelpMedia({commit, dispatch}, formData) {
        try {
            const res = await axios.post('/help_media.json', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-CSRF-Token': state.token,
                },
                onUploadProgress: (event) => {
                    const percentage = Math.round((100 * event.loaded) / event.total);
                    commit('setHelpFileUploadProgress', percentage);
                },
            });
            if(res.data.error) throw res.data.message
            dispatch('fetchHelpDrawer', res.data.weight);
            commit('setHelpFileUploadProgress', 0);
            return Promise.resolve(res.data)

        } catch(error) {
            return Promise.reject(error);
        }
    },

    async deleteHelpMedia({commit, dispatch}, id) {
        try {
            const res = await axios.delete(`/help_media/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                }
            });
            if(res.data.error) throw res.data.message
            dispatch('fetchHelpDrawer', res.data.weight);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)

        }
    },

}


export default {
    state,
    getters,
    mutations,
    actions,
}