import axios from 'axios';
import Documents from '../project/documents';

/* * * STATE * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    newsMenu: null,
    articlesSlides: null,
    recentArticles: null,

    //Topic
    topicArticles: null,
    topicSubscribers: null,
    topicsToSubscribeList: null,
    topicProjects: null,
    //Topic Forum
    //Threads
    //in forum.js

    //Article
    article: null,
    articleScheme: null,

    //ArticleTags
    newArticleTagsScheme: null,

    //ArticleGroups
    newArticleGroupsScheme: null,

    //ArticleContacts
    newArticleContactsScheme: null,

    //ArticleUrls
    articleUrlsScheme: null,
}

/* * * GETTERS * * */
const getters = {
    newsMenu: state => state.newsMenu,
    articlesSlides: state => state.articlesSlides,
    recentArticles: state => state.recentArticles,
    //Topic
    topicArticles: state => state.topicArticles,
    topicSubscribers: state => state.topicSubscribers,
    topicsToSubscribeList: state => state.topicsToSubscribeList,
    topicProjects: state => state.topicProjects,
    //Topic Forum
    //Threads
    // In forum.js
    //Article
    article: state => state.article,
    articleScheme: state => state.articleScheme,

    //ArticleTags
    newArticleTagsScheme: state => state.newArticleTagsScheme,

    //ArticleGroups
    newArticleGroupsScheme: state => state.newArticleGroupsScheme,

    //ArticleContacts
    newArticleContactsScheme: state => state.newArticleContactsScheme,

    //Article
    articleUrlsScheme: state => state.articleUrlsScheme,
}

/* * * MUTATIONS * * */
const mutations = {
    setNewsMenu: (state, data) => state.newsMenu = data,
//Topic
    setArticles(state, data){
        state.articlesSlides = data.slide
        state.recentArticles = data.recent_articles
    },
    setTopicArticles: (state, data) => state.topicArticles = data,
    setTopicSubscribers: (state, data) => state.topicSubscribers = data,
    setTopicsToSubscribeList: (state, data) => state.topicsToSubscribeList = data,
    setTopicProjects: (state, data) => state.topicProjects = data,

//Article
    setArticle: (state, data) => state.article = data,

    setArticleScheme: (state, data) => state.articleScheme = data,

    setNewArticle: (state, data) => {
        if(!state.topicArticles) return
        let newArray = state.topicArticles
        newArray.articles.unshift(data)
        state.topicArticles = null
        state.topicArticles = newArray
    },

    setUpdatedArticle: (state, data) => {
            if(state.topicArticles !== null) {
            let newArray = state.topicArticles
            let articleObjectIndex = newArray.findIndex(( article => article.id ===  data.id));
            newArray[articleObjectIndex] = data
            state.topicArticles = null
            state.topicArticles = [...newArray]
        } else {
            let updatedArticle = state.article
            updatedArticle.body = data.body
            state.article = null
            state.article = updatedArticle
        }

    },
    setDeletedArticle: (state, data) => {
        if (!state.topicArticles) return;

        let topicArticlesCopy = state.topicArticles
        topicArticlesCopy.articles = state.topicArticles.articles.filter(article => article.id !== data.id)
        state.topicArticles = null
        state.topicArticles = topicArticlesCopy

    },
//ArticleTags
    setNewArticleTags: (state, data) => {
        let articleCopy = state.article
        articleCopy.folder_tags.push(...data)
        state.article = null
        state.article = articleCopy
    },
    setNewArticleTagsScheme: (state, data) => state.newArticleTagsScheme = data,
    setDeletedArticleTags: (state, data) => {
        let articleCopy = state.article
        let newTagsArray = state.article.folder_tags.filter(articleTag => articleTag.id !== data.id)
        articleCopy.folder_tags = newTagsArray
        state.article = null
        state.article = articleCopy
    },
//ArticleGroups

    setNewArticleGroups: (state, data) => {
        let articleCopy = state.article
        articleCopy.group_links.push(data)
        state.article = null
        state.article = articleCopy
    },
    setNewArticleGroupsScheme: (state, data) => state.newArticleGroupsScheme = data,

    setDeletedGroups: (state, data) => {
        let articleCopy = state.article
        let newGroupArray = state.article.group_links.filter(articleGroup => articleGroup.id !== data.id)
        articleCopy.group_links = newGroupArray
        state.article = null
        state.article = articleCopy
    },
//ArticleContacts

    setNewArticleContacts: (state, data) => {
        let articleCopy = state.article
        articleCopy.contacts.push(data)
        state.article = null
        state.article = articleCopy
    },

    setNewArticleContactsScheme: (state, data) => state.newArticleContactsScheme = data,

    setDeletedArticleContacts: (state, data) => {
        let articleCopy = state.article
        let newContactsArray = state.article.contacts.filter(articleContact => articleContact.id !== data.id)
        articleCopy.contacts = newContactsArray
        state.article = null
        state.article = articleCopy
    },
//ArticleUrls

    setArticleUrlsScheme: (state, data) => state.articleUrlsScheme = data,

    setNewArticleUrls: (state, data) => {
        let articleCopy = state.article
        articleCopy.urls.push(data)
        state.article = null
        state.article = articleCopy
    },

    setUpdatedArticleUrls:  (state, data) => {
        let articleCopy = state.article
        let articleUrlIndex = articleCopy.urls.findIndex(( articleUrl => articleUrl.id ===  data.id));
        articleCopy.urls[articleUrlIndex] = data
        state.article = null
        state.article = articleCopy
    },
    setDeletedArticleUrls: (state, data) => {
        let articleCopy = state.article
        let newUrlsArray = state.article.urls.filter(articleUrl => articleUrl.id !== data.id)
        articleCopy.urls = newUrlsArray
        state.article = null
        state.article = articleCopy
    },
//Articles List
    setArticlesList:  (state, data) => state.recentArticles = data,
}



/* * * ACTIONS * * */
const actions = {
//Menu
    async fetchNewsMenu({commit}){
        console.log('fetch menu')
        try {
            const res = await axios.get(`/topic_categories.json`)
            if(res.data.error) throw res.data.message
            commit('setNewsMenu', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

//Recent Articles Page  * * * * *
   async fetchArticles({commit}) {
        try {
            const res = await  axios.get(`/topic_articles.json`)
            if(res.data.error) throw res.data.message
            commit('setArticles', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },


//Topic  * * * * *

    fetchTopicArticles({commit}, id){
        axios.get(`/topics/${id}/articles.json`).then(res => {
            commit('setTopicArticles', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Subscribers
    fetchTopicSubscribers({commit}, id){
        axios.get(`/topics/${id}/subscribers_list.json`).then(res => {
            commit('setTopicSubscribers', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchTopicsToSubscribeList({commit}, id){
        axios.get(`/users/${id}/topics_subscription.json`).then(res => {
            commit('setTopicsToSubscribeList', res.data.to_be_subscribed);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchTopicProjects({commit}, id){
        axios.get(`/topics/${id}/topic_projects.json`).then(res => {
            commit('setTopicProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchArchivedTopicProjects({commit}, id) {
        axios.get(`/topics/${id}/topic_projects.json?archive`).then(res => {
            commit('setTopicProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    subscribeTopic({commit}, {id, type}) {
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            topic: {
                id: id
            }
        };

        let action = type ? 'subscribed' : 'unsubscribed'
        axios.post(`/topics/${id}/subscribe.json`, params , {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: action, text: 'topic'});
                commit('setTopicSubscribers', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch(error => {
            commit('setError', error.toString());
        });
    },




//ARTICLE * * * * *
    fetchArticle({commit, dispatch}, id){
        axios.get(`/topic_articles/${id}.json`).then(res => {
            commit('setArticle', res.data);
            commit('setDocumentsList', res.data.documents);
            dispatch('fetchTopicArticles', res.data.topic_id)
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewArticleScheme({commit}, id){
        axios.get(`/topics/${id}/topic_articles/new.json`).then(res => {
            commit('setArticleScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async validateArticleName({commit}, {title, topic_id: topic_id}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article: {
                    title,
                    topic_id: topic_id
                }
            };

            const res = await axios.post(`/topic_articles/validate_article_title.json`, params , { headers: {'Content-Type': 'application/json'}})

            return Promise.resolve(res.data);

        } catch(error) {
            commit('setError', error);
        }
    },

    fetchNewArticleKeyDocsScheme({commit}, id){
        axios.get(`/topic_articles/new.json`).then(res => {
            commit('setArticleScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createArticle({commit}, newArticle) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article: newArticle
            };

            const res = await axios.post(`/topic_articles.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: 'created', text: 'a new article'});
            commit('setNewArticle', res.data);
            return Promise.resolve(res.data);


        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create article', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditArticleScheme({commit}, id){
        axios.get(`/topic_articles/${id}/edit.json`).then(res => {
            commit('setArticleScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateArticle({commit, dispatch}, {id, updatedArticle}){
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article: updatedArticle,
                id: id,
            };

            const res = await axios.put(`/topic_articles/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'article'});
            if(state.topicArticles) dispatch('fetchTopicArticles', updatedArticle.topic_id)
            if(state.article) dispatch('fetchArticle', id)
            return Promise.resolve();


        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update article', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

   async deleteArticle({commit}, id){
        try {

            const res = await  axios.delete(`/topic_articles/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'article'});
            commit('setDeletedArticle', res.data);
            return Promise.resolve(res.data)


        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete article', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//Article Tags

    fetchNewArticleTagsScheme({commit}, id){
        axios.get(`/topic_articles/${id}/topic_article_tags/new.json`).then(res => {
            commit('setNewArticleTagsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createArticleTags({commit}, newArticleTag) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article_tag: newArticleTag,
            };


            const res = await axios.post(`/topic_article_tags.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new tag'});
            commit('setNewArticleTags', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create article tag', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteArticleTag({commit}, id){

        try {
            const res = await axios.delete(`/topic_article_tags/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'tag'});
            commit('setDeletedArticleTags', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete article tag', text: error, timeout: 8000});
            return Promise.reject(error)
        }

     /*   axios.delete(`/topic_article_tags/${id}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'tag'});
                commit('setDeletedArticleTags', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });*/
    },

//Article Key Groups

    fetchNewArticleGroupsScheme({commit}, id){
        axios.get(`/topic_articles/${id}/topic_article_group_links/new.json`).then(res => {
            commit('setNewArticleGroupsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createArticleGroup({commit}, newArticleGroup) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article_group_link: newArticleGroup,
            };

            const res = await axios.post(`/topic_article_group_links.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new Key Group'});
            commit('setNewArticleGroups', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create article group', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteArticleGroup({commit}, id){
        try {
            const res = await axios.delete(`/topic_article_group_links/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Key Group'});
            commit('setDeletedGroups', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete article group', text: error, timeout: 8000});
            return Promise.reject(error)
        }

/*        axios.delete(`/topic_article_group_links/${id}.json	`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Key Group'});
                commit('setDeletedGroups', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });*/
    },

//Article Contacts

    fetchNewArticleContactsScheme({commit}, id){
        axios.get(`/topic_articles/${id}/topic_article_contacts/new.json`).then(res => {
            commit('setNewArticleContactsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createArticleContact({commit}, newArticleContact) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article_contact: newArticleContact,
            };

            const res = await axios.post(`/topic_article_contacts.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new Contact'});
            commit('setNewArticleContacts', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create article contact', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteArticleContact({commit}, id){
        try {
            const res = await axios.delete(`/topic_article_contacts/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Contact'});
            commit('setDeletedArticleContacts', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete article contact', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//Article URLs

    fetchNewArticleUrlsScheme({commit}, id){
        axios.get(`/topic_articles/${id}/topic_article_urls/new.json`).then(res => {
            commit('setArticleUrlsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createArticleUrl({commit}, newArticleUrls) {

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article_url: newArticleUrls
            };

            const res = await axios.post(`/topic_article_urls.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new URL'});
            commit('setNewArticleUrls', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create article url', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditArticleUrlsScheme({commit}, id){
        axios.get(`/topic_article_urls/${id}/edit.json`).then(res => {
            commit('setArticleUrlsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateArticleUrls({commit}, {id, updatedArticleUrls}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_article_url: updatedArticleUrls,
                id: id,
            };

            const res = await axios.put(`/topic_article_urls/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'URL'});
            commit('setUpdatedArticleUrls', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update article url', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteArticleUrl({commit}, id){
        try {
            const res = await axios.delete(`/topic_article_urls/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'URL'});
            commit('setDeletedArticleUrls', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete article url', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Articles List

    fetchArticleList({commit}, url){
        axios.get(`${url}`).then(res => {
            commit('setArticlesList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

}


export default {
    state,
    getters,
    mutations,
    actions,
}
