import axios from "axios";

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    tours: {
        "knowledgeHub-index": {
            hasDialogs: true,
            startDialog: {
                title: "Welcome to KnowledgeHub",
                subtitle: "KnowledgeHub Tour",
                body: "ICRT’s main source of information and resources. Here you can find: ",
                bodyList: ['Guidelines - In Principles', 'Guidelines - In Action','Structure & Governance', 'Key Documents' ]
            },
            endDialog: {
                title: "Thanks for taking the tour, now you are good to go.",
                subtitle: "KnowledgeHub Tour Completed",
                body: "Check \"i\" icons if you need help - watch KnowledgeHub Help Videos, run tours again. ",
                bodyList: []
            },
            steps:  [
                {
                    target:  '#v-knowledgehub-index-step-1',
                    header: {
                        title: '<strong>KnowledgeHub Content</strong> ',
                    },
                    content: `<p class="text-left"> Content in KnowledgeHub contains 4 sections. </p> <p>Joint Testing Guidelines is divided into 2 sections: </p>
                                <ui class="pb-4"> <li>Guidelines - In Principle</li>
                                <li>Guidelines - In Action</li></ui>`,
                    name: 'title',
                    params: {
                        placement: 'right',
                        enableScrolling: false,
                        highlight: true
                    },

                },

                {
                    target: '#v-knowledgehub-index-step-2',
                    header: {
                        title: '<strong>Structure and Governance</strong>',
                    },
                    content: `<p class="text-left">Information on the Structure and Governance of ICRT.</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },

                },
                {
                    target: '#v-knowledgehub-index-step-3',
                    header: {
                        title: '<strong>Key Documents</strong>',
                    },
                    content: `<p class="text-left">This section has Key Documents that support ICRT processes.</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },

                },
                {
                    target: '#v-knowledgehub-index-step-4',
                    header: {
                        title: '<strong>Help Drawer</strong>',
                    },
                    content: `<p class="text-left">You can access KnowledgeHub Help by clicking the 'i' icon. You will find videos and the option to run the tour again.</p>`,
                    name: 'help-drawer',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },

                },
            ],
            icon: 'kh-tour',
            color: 'kh',
        },
        "knowledgeHub-page": {
            hasDialogs: false,
            startDialog: null,
            endDialog: null,
            steps:  [
                {
                    target:  '#v-knowledgehub-page-index-step-1',
                    header: {
                        title: '<strong>KnowledgeHub Top Menu</strong> ',
                    },
                    content: `<p class="text-left">From this menu you can select other KnowledgeHub content.</p>`,
                    name: 'title',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-2',
                    header: {
                        title: '<strong>Page Breadcrumbs</strong>',
                    },
                    content: `<p class="text-left">This shows the current locations of the content</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-3',
                    header: {
                        title: '<strong>Title Bar</strong>',
                    },
                    content: `<p class="text-left">This is the Page title.</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-4',
                    header: {
                        title: '<strong>Bookmark Page</strong>',
                    },
                    content: `<p class="text-left">Option to bookmark content for quick access.</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-5',
                    header: {
                        title: '<strong>KnowledgeHub Bookmarks</strong>',
                    },
                    content: `<p class="text-left">You can access all bookmarked content and Pinned Documents from here</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'left',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-6',
                    header: {
                        title: '<strong>Page Top Navigation</strong>',
                    },
                    content: `<p class="text-left">You can navigate throughout the Book with Previous/ Next located in the Title Bar</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-7',
                    header: {
                        title: '<strong>Page Bottom Navigation</strong>',
                    },
                    content: `<p class="text-left">Previous/ Next buttons are also at the bottom of the page.</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-8',
                    header: {
                        title: '<strong>Page Content</strong>',
                    },
                    content: `<p class="text-left">Page Content is located in this area.</p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
                {
                    target: '#v-knowledgehub-page-index-step-9',
                    header: {
                        title: '<strong>Table of Content</strong>',
                    },
                    content: `<p class="text-left">Select any page to view. </p>`,
                    name: 'tab_content',
                    params: {
                        placement: 'top',
                        enableScrolling: false,
                        highlight: true
                    },
                },
            ],
            icon: null,
            color: 'kh',
        },
    },
    startTour: null,
    endTour: false,
    currentStep: null,
    userTours: null,
};

// Getters
const getters = {
    tours: state => state.tours,
    startTour: state => state.startTour,
    endTour: state => state.endTour,
    currentStep: state => state.currentStep,
    userTours: state => state.userTours,
};

// Mutations
const mutations = {
    setTours: (state, data) => state.tours = data,
    setStartTour: (state, data) => state.startTour = data,
    setEndTour: (state, data) => state.endTour = data,
    setCurrentStep: (state, data) => state.currentStep = data,
    setUserTours: (state, data) => state.userTours = data,
};

// Actions
const actions = {
    setStartTour({commit}, data) {
        commit('setStartTour', data);
    },

    async fetchUserTours({commit}, id) {
        try {
            const res = await axios.get(`/users/${id}/tours.json`);
            if(res.data.error) throw res.data.message
            commit('setUserTours', res.data);
            return Promise.resolve(res.data);
        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async finishUserTour({ commit }, {id, tour }) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                id: id,
                tour
            };

            const res = await axios.post(`/tours.json`, params ,{ headers: {'Content-Type': 'application/json'}});
            if(res.data.error) throw res.data.message
            commit('setStartTour', null);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    }

};

// Export
export default {
    state,
    getters,
    mutations,
    actions
}
