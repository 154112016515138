import axios from 'axios';

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    labsList: null,
//Lab
    lab: null,
    labScheme: null,

//Lab Reviews
    labReviews: null,
    labReviewScheme: null,

//Lab Projects
    labProjects: null,

//Lab Topics
    newLabTopicScheme: null,

//Lab Contacts
    labContactScheme: null,

//Lab Address
    labAddressScheme: null,

//Lab Language
    labLanguageScheme: null,
}

const getters = {
    labsList: state => state.labsList,
//Lab
    lab: state => state.lab,
    labScheme: state => state.labScheme,

//Lab Reviews
    labReviews: state => state.labReviews,
    labReviewScheme: state => state.labReviewScheme,

//Lab Projects
    labProjects: state => state.labProjects,

//Lab Topics
    newLabTopicScheme: state => state.newLabTopicScheme,

//Lab Contacts
    labContactScheme: state => state.labContactScheme,

//Lab Address
    labAddressScheme: state => state.labAddressScheme,

//Lab Language
    labLanguageScheme: state => state.labLanguageScheme,
}

const mutations = {
    setLabsList: (state, data ) => state.labsList = data,

//Lab
    setLab: (state, data ) => state.lab = data,
    setLabScheme: (state, data) => state.labScheme = data,
    setNewLab: (state, data) => {
        let newArray = state.labsList
        state.labsList = null
        state.labsList = newArray
    },
    setUpdatedLab: (state, data) => {
            state.lab = null
            state.lab = data
        if(state.labsList) {
            let newArray = state.labsList
            let labObjectIndex = newArray.findIndex(lab => lab.id ===  data.id);
            newArray[labObjectIndex] = data
            state.labsList = null
            state.labsList = [...newArray]
            state.lab = null
            state.lab = data
        }
    },

    setDeletedLab: (state, data) => {
        let newArray = state.labsList.filter(lab => lab.id !== data.id)
        state.labsList = null
        state.labsList = newArray
    },

    setLabLogo: (state, url) => {
        state.lab.logo = url
    },

//Activate
    setActivatedLab: (state, data) => {
        let activatedLab = state.lab
        activatedLab.key = data.key
        state.lab = null
        state.lab = activatedLab
    },

//DeActivate
    setDeActivatedLab: (state, data) => {
        let deActivatedLab = state.lab
        deActivatedLab.key = data.key
        state.lab = null
        state.lab = deActivatedLab
    },

//Review
    setLabReviews: (state, data) => state.labReviews = data,
    setLabReviewScheme: (state, data ) => state.labReviewScheme = data,
    setNewLabReview: (state, data) => {
        let newArray = state.labReviews
        newArray.unshift(data)
        state.labReviews = null
        state.labReviews = newArray
    },
    setUpdatedLabReview: (state, data) => {
        let newArray = state.labReviews
        let labObjectIndex = newArray.findIndex(( review => review.id ===  data.id));
        newArray[labObjectIndex] = data
        state.labReviews = null
        state.labReviews = [...newArray]
    },
    setDeletedLabReview: (state, data) => {
        let newArray = state.labReviews.filter(review => review.id !== data.id)
        state.labReviews = null
        state.labReviews = newArray
    },

//LAB projects
    setLabProjects: (state, data ) => state.labProjects = data,

//Topic
    setNewLabTopicScheme: (state, data) => state.newLabTopicScheme = data,
    setNewLabTopic: (state, data) => {
        let labCopy = state.lab
        labCopy.topics.push(data)
        state.lab = null
        state.lab = labCopy
    },
    setDeletedLabTopic: (state, data) => {
        let labCopy = state.lab
        labCopy.topics = state.lab.topics.filter(topic => topic.id !== data.id)
        state.lab = null
        state.lab = labCopy
    },

//Contact
    setLabContactScheme: (state, data ) => state.labContactScheme = data,
    setNewLabContact: (state, data) => {
        let labCopy = state.lab
        labCopy.contacts.push(data)
        state.lab = null
        state.lab = labCopy
    },
    setUpdatedLabContact: (state, data) => {
        let labCopy = state.lab
        labCopy.contacts = state.lab.contacts.filter(address => address.id !== data.id)
        labCopy.contacts.push(data)
        state.lab = null
        state.lab = labCopy
    },
    setDeletedLabContact: (state, data) => {
        let labCopy = state.lab
        labCopy.contacts = state.lab.contacts.filter(address => address.id !== data.id)
        state.lab = null
        state.lab = labCopy
    },

//Address
    setLabAddressScheme: (state, data ) => state.labAddressScheme = data,
    setNewLabAddress: (state, data) => {
        let labCopy = state.lab
        labCopy.addresses.push(data)
        state.lab = null
        state.lab = labCopy
    },
    setUpdatedLabAddress: (state, data) => {
        let labCopy = state.lab
        let labObjectIndex = state.lab.addresses.findIndex((address => address.id ===  data.id));
        labCopy.addresses[labObjectIndex] = data
        state.lab = null
        state.lab = labCopy
    },
    setDeletedLabAddress: (state, data) => {
        let labCopy = state.lab
        labCopy.addresses = state.lab.addresses.filter(address => address.id !== data.id)
        state.lab = null
        state.lab = labCopy
    },

//Language
    setLabLanguageScheme: (state, data ) => state.labLanguageScheme = data,
    setNewLabLanguage: (state, data) => {
        let labCopy = state.lab
        labCopy.languages.push(data)
        state.lab = null
        state.lab = labCopy
    },
    setDeletedLabLanguage: (state, data) => {
        let labCopy = state.lab
        labCopy.languages = state.lab.languages.filter(language => language.id !== data.id)
        state.lab = null
        state.lab = labCopy
    },
}

const actions = {
//Labs List
    async fetchLabsList({commit}) {
        try {
            const res = await  axios.get(`/labs.json`)
            if(res.data.error) throw res.data.message
            commit('setLabsList', res.data);
            return Promise.resolve();
        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create new lab', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

// Lab
    fetchLab({commit}, id) {
        axios.get(`/labs/${id}.json`).then(res => {
            commit('setLab', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//New Lab (new.json)
    fetchNewLabScheme({commit}){
        axios.get(`/labs/new.json`).then(res => {
            commit('setLabScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//POST new Lab
    async createLab({commit}, lab) {
        try {
            const res = await axios.post(`/labs.json`, lab , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new lab', pageType: 'newLab', data: res.data});
            commit('setNewLab', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create new lab', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//Fetch Lab Edit.json
    fetchEditLabScheme({commit}, id){
        axios.get(`/labs/${id}/edit.json`).then(res => {
            commit('setLabScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Update Lab
    async updateLab({commit, dispatch}, {id, updatedLab}){

        try {
            const res = await axios.put(`/labs/${id}.json`, updatedLab, {headers: {'Content-Type': 'application/json'}})

            if (res.data.error) return commit('setAlertResponse', { type: 'error', text: res.data.message})
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'lab'});
            dispatch('fetchLab', id);
            return Promise.resolve();
        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update lab', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//Delete Lab
    async deleteLab({commit}, id){
        try {
            const res = await axios.delete(`/labs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })
                if (res.data.error) return commit('setAlertResponse', { type: 'error', text: res.data.message})
                commit('setAlertResponse', { type: 'successful', action: 'deleted', text: 'lab'});
                commit('setDeletedLab', res.data);
                return Promise.resolve();
        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete lab', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Activate Lab

   async activateLab({commit}, id){
        try {
            const res = await axios.get(`/labs/${id}/activate.json`)
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'activated', text: 'the lab'});
            commit('setActivatedLab', res.data);
            return Promise.resolve();
        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'deactivate lab', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//DeActivate Lab

    async deActivateLab({commit}, id){
        try {
            const res = await axios.get(`/labs/${id}/deactivate.json`)
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deactivated', text: 'the lab'});
            commit('setDeActivatedLab', res.data);
            return Promise.resolve();
        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'deactivate lab', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

// Lab Reviews
   fetchLabReviews({commit}, id) {
            axios.get(`/labs/${id}/reviews.json`).then(res => {
                commit('setLabReviews', res.data);
            }).catch(error => {
                commit('setError', error.toString());
            })
        },

    fetchNewLabReviewScheme({commit}, id){
        axios.get(`/labs/${id}/lab_reviews/new.json`).then(res => {
            commit('setLabReviewScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createLabReview({commit}, newLabReview) {
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_review: newLabReview,
            };

            const res = await axios.post(`/lab_reviews.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new review to lab'});
            commit('setNewLabReview', res.data);
            return Promise.resolve()


        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditLabReviewScheme({commit}, id){
        axios.get(`/lab_reviews/${id}/edit.json`).then(res => {
            commit('setLabReviewScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateLabReview({commit}, {id, updatedLabReview}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_review: updatedLabReview,
                id: id,
            };

            const res = await   axios.put(`/lab_reviews/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'the review'});
            commit('setUpdatedLabReview', res.data);
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteLabReview({commit}, id){
        try {
            const res = await axios.delete(`/lab_reviews/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'the review'});
            commit('setDeletedLabReview', res.data);
            return Promise.resolve()

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

// Lab Projects
    fetchLabProjects({commit}, {id, archived = false}) {
        let url = `/labs/${id}/lab_projects_list.json`
        if(archived) url += '?archive'
        axios.get(url).then(res => {
            commit('setLabProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


//Lab Topics

    fetchLabTopics({commit}, id){
        axios.get(`/labs/${id}/lab_project_topics.json`).then(res => {
            commit('setLabTopics', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


    fetchNewLabTopicScheme({commit}, id){
        axios.get(`/labs/${id}/lab_project_topics/new.json`).then(res => {
            commit('setNewLabTopicScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createLabTopic({dispatch, commit}, newLabTopic) {

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_project_topic: newLabTopic,
            };

            const res = await axios.post(`/lab_project_topics.json`, params , {headers: {'Content-Type': 'application/json'}})


            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'lab topic'});
            commit('setNewLabTopic', res.data);
            dispatch('fetchLabsList')
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create lab topic', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteLabTopic({dispatch, commit}, id){
        try {
            const res = await axios.delete(`/lab_project_topics/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'removed', text: 'lab topic'});
            commit('setDeletedLabTopic', res.data);
            dispatch('fetchLabsList')
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete lab topic', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//Lab Contacts

    fetchLabContacts({commit}, id){
        axios.get(`/labs/${id}/lab_contacts.json`).then(res => {
            commit('setLabContacts', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewLabContactScheme({commit}, id){
        axios.get(`/labs/${id}/lab_contacts/new.json`).then(res => {
            commit('setLabContactScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createLabContact({commit}, newLabContact) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_contact: newLabContact,
            };

            const res = await axios.post(`/lab_contacts.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new lab contact'});
            commit('setNewLabContact', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create lab contact', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditLabContactScheme({commit}, id){
        axios.get(`/lab_contacts/${id}/edit.json`).then(res => {
            commit('setLabContactScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateLabContact({commit}, {id, updatedLabContact}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_contact: updatedLabContact,
                id: id,
            };

            const res = await axios.put(`/lab_contacts/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})


            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'lab contact'});
            commit('setUpdatedLabContact', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update lab contact', text: error, timeout: 8000});
            return Promise.reject(error)
        }

    },

    async deleteLabContact({commit}, id){
        try {
            const res = await  axios.delete(`/lab_contacts/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'lab contact'});
            commit('setDeletedLabContact', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }

    },

// Lab Addresses

    fetchLabAddress({commit}, id){
        axios.get(`/labs/${id}/lab_addresses.json`).then(res => {
            commit('setLabAddress', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewLabAddressScheme({commit}, id){
            axios.get(`/labs/${id}/lab_addresses/new.json`).then(res => {
                commit('setLabAddressScheme', res.data);
            }).catch(error => {
                commit('setError', error.toString());
            })
        },

    async createLabAddress({commit}, newLabAddress) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_address: newLabAddress,
            };

            const res = await axios.post(`/lab_addresses.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new lab address'});
            commit('setNewLabAddress', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create lab address', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditLabAddressScheme({commit}, id){
        axios.get(`/lab_addresses/${id}/edit.json`).then(res => {
            commit('setLabAddressScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

   async updateLabAddress({commit}, {id, updatedLabAddress}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_address: updatedLabAddress,
                id: id,
            };

            const res = await axios.put(`/lab_addresses/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'lab address'});
            commit('setUpdatedLabAddress', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update lab address', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteLabAddress({commit}, id){

        try {
            const res = await axios.delete(`/lab_addresses/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'lab address'});
            commit('setDeletedLabAddress', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete lab address', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

// Lab Language

    fetchNewLabLanguageScheme({commit}, id){
        axios.get(`/labs/${id}/lab_languages/new.json`).then(res => {
            commit('setLabLanguageScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createLabLanguage({commit}, newLabLanguage) {

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lab_language: newLabLanguage,
            };

            const res = await  axios.post(`/lab_languages.json`, params , {headers: {'Content-Type': 'application/json'}})


            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new lab language', pageType: 'newLanguage'});
            commit('setNewLabLanguage', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create lab language', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteLabLanguage({commit}, id){

        try {

            const res = await axios.delete(`/lab_languages/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'lab language'});
            commit('setDeletedLabLanguage', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete lab language', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
