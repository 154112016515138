import axios from 'axios';


/* * * STATE * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    projectHistory: null,
}

/* * * GETTERS * * */
const getters = {
    projectHistory: state => state.projectHistory,
}

/* * * MUTATIONS * * */
const mutations = {
    setProjectHistory: (state, projectHistory) => state.projectHistory = projectHistory,
    setUpdateHistory(state, {data: data, id: id}){
       let newArray = state.projectHistory
        for(const property of Object.keys(newArray)) {
            for (const system_log of Object.keys(newArray[property])){
                if(newArray[property][system_log].id === data.id){
                    newArray[property][system_log].hide = data.hide
                }
            }
        }
        state.projectHistory = null
        state.projectHistory = newArray
    },
}

/* * * ACTIONS * * */
const actions = {

    fetchProjectHistory({commit}, id) {
        axios.get(`/projects/${id}/history.json`).then(res => {
            commit('setProjectHistory', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

   async hideHistory({commit}, {id, value, isKh = false}){
       try {
           const params = {
               charset: 'UTF8',
               authenticity_token: state.token,
               hide: value,
           };
           let text = value ? 'hide' : 'show'
           const res = await axios.post(`/system_logs/${id}/hide.json`, params, {headers: {'Content-Type': 'application/json'}})
            commit('setAlertResponse', {type: 'successful', action: text, text: 'activity from history!'});
            if(!isKh) commit('setUpdateHistory',  {data: res.data, id: id});
           return Promise.resolve();

       } catch(error) {
           commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
           return Promise.reject(error)
       }
      },
}


export default {
    state,
    getters,
    mutations,
    actions,
}
