import axios from 'axios';

const state = {
    newProjects: null,
    latestPosts: null,
    lastActivities:null,
    calendar:null,
    upcomingKeyDates:null,
}

const getters = {
    newProjects: state => state.newProjects,
    latestPosts: state => state.latestPosts,
    lastActivities: state => state.lastActivities,
    calendar: state => state.calendar,
    upcomingKeyDates: state => state.upcomingKeyDates,

}

const mutations ={
    setCards: (state, data) => {
        state.newProjects = data.new_projects;
        state.latestPosts = data.latest_posts;
        state.lastActivities = data.last_activities;
        state.calendar = data.calendar
        state.upcomingKeyDates = data.upcoming_key_dates
    },
}

const actions = {
    async fetchDashboardCards({commit}) {
        try {
            const res = await axios.get(`/dashboard/data.json`)
            if(res.data.error) throw res.data.message
            commit('setCards', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

}

export default {
    state,
    getters,
    mutations,
    actions,
}
