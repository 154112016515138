import axios from 'axios';

/* STATE * * * * * * * * * * * * * * * * * * */
const state = {
    user: null,
    org: null,
    gdpr: null,
    memType: null,
    preferences: null,
    error: null,
    exception: null,
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    privacy_policy: null,
    dashboardSettings: null,
    favourites: null,
    khBookmarks: null,
    pinnedKeyDocs: null,
    dashboardList: ['new_projects', 'favorites', 'calendar' , 'latest_posts',],
}

/* GETTERS * * * * * * * * * * * * * * * * * * */
const getters = {
    user: state => state.user,
    org: state => state.org,
    gdpr: state => state.gdpr,
    memType: state => state.memType,
    favourites: state => state.favourites,
    khBookmarks: state => state.khBookmarks,
    pinnedKeyDocs: state => state.pinnedKeyDocs,
    error: state => state.error,
    exception: state => state.exception,
    token: state => state.token,
    navTab: state => state.navTab,
    subscriptions: state => state.subscriptions,
    privacy_policy: state => state.privacy_policy,
    dashboardSettings: state => state.dashboardSettings,
}

/* MUTATIONS * * * * * * * * * * * * * * * * * * */
const mutations = {
    setSessionUserPhoto(state, photo){
        state.user.photo = photo
    },

    setState: (state, data) => {
        state.user = data.user;
        state.org = data.org;
        state.gdpr = data.gdpr,
        state.memType = data.mem_type;
        state.preferences = data.preferences;
        state.favourites = null
        state.favourites = data.recent_projects
        state.khBookmarks = data.bookmarks
        state.pinnedKeyDocs = data.doc_pins
        state.dashboardSettings = data.preferences.data

    },

    setItem: (state, data) => {
        Object.keys(data).forEach(key => {
            state[key] = data[key];
        });
    },

    setError: (state, error) => {
        state.error = error
    },
    setException: (state, exception) => state.exception = exception,
    setPrivacyPolicy: (state, policy) => state.privacy_policy = policy,
}


/* ACTIONS * * * * * * * * * * * * * * * * * * */
const actions = {

    session({commit},params) {
        commit('setItem', params);
    },

    async fetchSession({commit}) {
        try {
            const res = await axios.get('/settings.json')
            if(res.status !== 200) return
            if(res.data.error) throw res.data.error
            commit('setState', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    fetchPrivacyPolicy({commit}) {
        axios.get('/privacy_policy').then(res => {
            commit('setPrivacyPolicy', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    deleteRecentProject({commit, state, dispatch}, id) {
        axios.delete(`/projects/${id}/preference.json`, {
            headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': state.token}}).then(res => {
            dispatch('fetchSession');
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async pinRecentProject({commit, state, dispatch}, id) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
            };

            const res = await axios.post(`/settings/pin/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message

            dispatch('fetchSession')
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error});
            return Promise.reject(error);
        }
    },

    updateDashboardSettings({dispatch, commit, state}, settings) {

        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            settings: settings,
        };

        axios.post(`/settings/dashboard.json`, params).then(res => {
            if(res.status !== 200) throw res.response.error;
            dispatch('fetchSession')
        }).catch(error => {
            commit('setError', error);
        })
    },

    resetError({commit}){
        commit('setError', null);
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
}