import axios from 'axios';
import planning from "../modules/project/planning";
import participation from "../modules/project/participation";
import staff from '../modules/project/staff';
import projectDocuments from "../modules/project/documents";
import forum from '../modules/project/forum';
import history from "../modules/project/history";

/* STATE * * * * * * * * * * * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
//Project
    project: null,
    newProject: {},
    projectScheme: null,
//Project Tools
    projectTools: null,
//ProjectS
    allProjects: null,
    myProjects: null,
    archivedProjects: null,
//CostBand
    costBands: null,
//Project Labs
    projectLabScheme: null,
    selectedProjects: null,
    fixedNumbers: null,

    projectLabTestCostScheme: null,
//Project Topics
    projectTopics: null,
    newProjectTopicScheme: null,
}

/* GETTERS * * * * * * * * * * * * */
const getters = {
//Project
    project: state => state.project,
    newProject:state => state.newProject,
    projectScheme: state => state.projectScheme,

//Project Tools
    projectTools: state => state.projectTools,

//ProjectS
    allProjects: state => state.allProjects,
    myProjects: state => state.myProjects,
    archivedProjects: state => state.archivedProjects,

//CostBand
    costBands: state => state.costBands,

//Project Labs
    projectLabScheme: state => state.projectLabScheme,
    selectedProjects: state => state.selectedProjects,
    fixedNumbers: state => state.fixedNumbers,

    projectLabTestCostScheme: state => state.projectLabTestCostScheme,

//Project Topics
    projectTopics:  state => state.projectTopics,
    newProjectTopicScheme:  state => state.newProjectTopicScheme,
}

/* MUTATIONS * * * * * * * * * * * * */
const mutations = {
//Project
    setProject: (state, project) => {
        state.project = null
        state.project = project
    },
    setProjectProperty: (state, params) => {
        state.newProject = {
            ...state.newProject,
            ...params,
        }
    },
    setProjectScheme: (state, projectScheme) => state.projectScheme = projectScheme,

//Project Tools
    setProjectTools: (state, projectScheme) => state.projectTools = projectScheme,

//ProjectS
    setAllProjects: (state, data) => state.allProjects = data,
    setMyProjects: (state, data) => state.myProjects = data,
    setArchivedProjects: (state, data) => state.archivedProjects = data,
//CostBand
    setCostBands: (state, costBands) => state.costBands = costBands,
//Project Labs

    setProjectLabScheme: (state, data) => state.projectLabScheme = data,

    setNewProjectLab: (state, data) => {
        let projectCopy = state.project
        projectCopy.labs.push(data)
        state.project = null
        state.project = projectCopy
    },

    setUpdatedProjectLab: (state, data) => {
        let projectCopy = state.project
        let labIndex = projectCopy.labs.findIndex((lab => lab.id ===  data.id));
        projectCopy.labs[labIndex] = data
        state.project = null
        state.project = projectCopy
    },
    setDeletedProjectLab: (state, data) => {
        let projectCopy = state.project
        projectCopy.labs = state.project.labs.filter(lab => lab.id !== data.id)
        state.project = null
        state.project = projectCopy
    },
    setSelectedProjects: (state, data) => state.selectedProjects = data,
    setFixedNumbers: (state, data) => state.fixedNumbers = data,

    setProjectLabTestCostScheme: (state, data ) => state.projectLabTestCostScheme = data,

//Project Topics
    setProjectTopics: (state, data) => state.projectTopics = data,
    setNewProjectTopicScheme:  (state, data) => state.newProjectTopicScheme = data,
    setNewProjectTopic:  (state, data) => {
        let newArray = state.projectTopics
        newArray.push(data)
        state.projectTopics = null
        state.projectTopics = newArray
    },
    setDeletedProjectTopic:  (state, data) => {
        let newArray = state.projectTopics.filter(topic => topic.topic_id !== data.topic_id)
        state.projectTopics = null
        state.projectTopics = newArray
    },
}

/* ACTIONS * * * * * * * * * * * * */
const actions = {

//Project
    async fetchProject({commit}, id) {
        try {
            const res = await axios.get(`/projects/${id}.json`)
            if(res.data.error) throw res.data.message
            commit('setProject', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    setProjectProperty({commit}, params){
        commit('setProjectProperty', params);
    },

    fetchProjectScheme({commit}) {
        axios.get(`/projects/new.json`).then(res => {
            commit('setProjectScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createProject({commit},project) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project,
            };

            const res = await axios.post(`/projects.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'created', text: 'a new Project', data: res.data});
            commit('setProject', res.data);

            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchEditProjectScheme({commit}, id) {
        try {
            const res = await axios.get(`/projects/${id}/edit.json`);

            if(res.data.error) throw res.data.message
            commit('setProjectScheme', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async updateProject({commit, dispatch}, { project, id, search= false } ) {
        try {

            if(!project) throw "invalid project";

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project: project,
                id: id,
            };

            const res = await axios.put(`/projects/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'Project'});
            if(search) commit('updateSearchResults', res.data)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 8000});
            return Promise.reject();
        }

    },

    async deleteProject({commit}, id) {

        try {
            const res = await  axios.delete(`/projects/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            });

            if(res.data.error) throw res.data.message
            commit('setProject', res.data);
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Project', pageType: 'deletedProject'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

//Project Tools
    async fetchProjectTools({commit}, id) {
        try {
            const res = await axios.get(`/projects/${id}/sso_links.json`)
            if(res.data.error) throw res.data.message
            commit('setProjectTools', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

//Test Cost
    async updateCurrentProjectTestCost({commit, dispatch}, {cost, id, isReports = false} ) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                test_cost: cost,
            };

            const res = await axios.post(`/projects/${id}/update_test_cost.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'the Project Test Cost!'});
            dispatch('fetchProject', id)
            if(isReports) dispatch('fetchReadyReports', 'reports/price_list')
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async updateProjectTestCostInSearch({commit}, {cost, id} ) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                test_cost: cost,
            };

            const res = await axios.post(`/projects/${id}/update_test_cost.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'the Project Test Cost!'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

//ProjectS
    fetchAllProjects({commit}) {
        axios.get('/projects.json').then(res => {
            commit('setAllProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchMyProjects({commit}) {
        axios.get('/my_projects.json').then(res => {
            commit('setMyProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchArchivedProjects({commit}) {
        axios.get('/projects.json?archive').then(res => {
            commit('setArchivedProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Cost Band
    fetchCostBands({commit}, id) {
        axios.get(`/projects/${id}/band.json`).then(res => {
            commit('setCostBands', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Project Labs

    fetchNewProjectScheme({commit}, id){
        axios.get(`/projects/${id}/project_labs/new.json`).then(res => {
            commit('setProjectLabScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createProjectLab({commit}, newProjectLab) {

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project_lab: newProjectLab,
            };

            const res = await axios.post(`/project_labs.json `, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: 'added', text: 'new lab'});
            commit('setNewProjectLab', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    fetchEditProjectLabScheme({commit}, id){
        axios.get(`/project_labs/${id}/edit.json`).then(res => {
            commit('setProjectLabScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateProjectLab({commit, dispatch}, {id, updatedProjectLab}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project_lab: updatedProjectLab,
            };

            const res = await axios.put(`/project_labs/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'lab'});
            dispatch('fetchProject', updatedProjectLab.project_id)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
     },

    async deleteProjectLab({commit}, id){
        try {
            const res = await axios.delete(`/project_labs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'lab'});
            commit('setDeletedProjectLab', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },
    
    fetchNewProjectLabTestCostScheme({commit}, project_lab_id){
        axios.get(`/project_labs/${project_lab_id}/test_costs/new.json`).then(res => {
            commit('setProjectLabTestCostScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createProjectLabTestCost({commit}, {test_cost, project_lab_id}) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                test_cost: test_cost,
            };

            const res = await axios.post(`/project_labs/${project_lab_id}/test_costs.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: 'added', text: 'NEW Project Lab Test Cost'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    fetchEditProjectLabTestCostScheme({commit}, id){
        axios.get(`/test_costs/${id}/edit.json`).then(res => {
            commit('setProjectLabTestCostScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateProjectLabTestCost({commit, dispatch}, {id, test_costs}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                test_cost: test_costs,
            };
            const res = await axios.put(`/test_costs/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async deleteProjectLabTestCost({commit}, id){
        try {
            const res = await axios.delete(`/test_costs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Project Lab Test Cost'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },


//Different Types Projects
    fetchSelectedProjects({commit}, url) {
        axios.get(`${url}`).then(res => {
            commit('setSelectedProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Fixednumber Override

    fetchFixedNumberOverride({commit}) {
        axios.get(`/fixednumbers.json`).then(res => {
            commit('setFixedNumbers', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Project Topics
    fetchProjectTopics({commit}, id) {
        axios.get(`/projects/${id}/topic_links.json`).then(res => {
            commit('setProjectTopics', res.data.related_topics);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewProjectTopicScheme({commit}, id) {
        axios.get(`/projects/${id}/topic_links/new.json`).then(res => {
            commit('setNewProjectTopicScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async fetchNewProjectProposedCode({commit}, year) {
        try {
            const res = await axios.get(`/projects/${year}/proposed_icrtcode.json`)
            if(res.data.error) throw res.data.message
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async validateIcrtCode({commit}, {id, icrtcode}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                icrtcode: icrtcode,
            };

            const res = await axios.post(`/projects/${id}/project_code_validation.json`, params , {headers: {'Content-Type': 'application/json'}})
            return Promise.resolve(res.data)

        } catch(error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }

    },

    async createProjectTopic({commit}, newProjectTopic) {

       try {
           const params = {
               charset: 'UTF8',
               authenticity_token: state.token,
               topic_link: newProjectTopic,
           };

           const res = await axios.post(`/topic_links.json `, params , {headers: {'Content-Type': 'application/json'}})

           if(res.data.error) throw res.data.message
           commit('setNewProjectTopic', res.data);
           commit('setAlertResponse', { type: 'successful', action: 'added', text: 'a new related topic', pageType: 'projectTopic'});
           return Promise.resolve();

       } catch (error) {
           commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
           return Promise.reject();
       }
    },

    async deleteProjectTopic({commit}, id){
        try {
            const res = await axios.delete(`/topic_links/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setDeletedProjectTopic', res.data);
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'the topic', pageType: 'projectTopic'});
            return Promise.resolve();

        } catch (error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }

       /* axios.delete(`/topic_links/${id}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'the topic', pageType: 'projectTopic'});
                commit('setDeletedProjectTopic', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful', action: '', text: ''});
            }
        }).catch((error) => {
            commit('setError', error);
        });*/
    },

    async afSync({commit}, url) {
        try {
            const res = await axios.get(url)
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: '', text: 'completed AF sync', timeout: 4000 });
            return Promise.resolve();
        } catch (error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    modules: {
        planning,
        participation,
        staff,
        projectDocuments,
        forum,
        history
    }
}